import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Để áp dụng CSS cải tiến

// Hàm định dạng ngày giờ để so sánh
const formatDateTimeForComparison = (dateTime) => {
  const [date, time] = dateTime.split(' ');
  if (!date || !time) return '';
  const [day, month, year] = date.split('/').map(Number);
  return `${year}-${month}-${day} ${time}`;
};

// Hàm lọc dữ liệu
const filterData = (data, term) => {
  return data.filter(row => {
    const date = new Date(formatDateTimeForComparison(row['Date Time']));
    return Object.values(row).some(val => String(val).toLowerCase().includes(term.toLowerCase()));
  });
};

const App = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 20;

  // Lấy dữ liệu từ API
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Bắt đầu trạng thái tải
      try {
        const response = await axios.get('http://103.166.228.48:5000/api/data', {
          params: {
            page: currentPage,
            limit: itemsPerPage
          }
        });
        setData(response.data);
        setFilteredData(response.data); // Set filteredData initially
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Kết thúc trạng thái tải
      }
    };

    fetchData();
  }, [currentPage]);

  // Cập nhật dữ liệu khi từ khóa tìm kiếm thay đổi
  useEffect(() => {
    const filtered = filterData(data, searchTerm);
    setFilteredData(filtered);
    setCurrentPage(1); // Reset về trang đầu tiên khi lọc
  }, [data, searchTerm]);

  // Phân trang
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredData.length / itemsPerPage)));

  // Dữ liệu hiện tại của trang
  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Render phân trang
  const renderPagination = () => {
    let pages = [];
    const pageLimit = 7;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    pages.push(
      <button key="prev" onClick={handlePreviousPage} disabled={currentPage === 1}>
        &lt;
      </button>
    );

    if (totalPages <= pageLimit) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => paginate(i)}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage <= Math.floor(pageLimit / 2)) {
        for (let i = 1; i <= pageLimit; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => paginate(i)}
              className={currentPage === i ? 'active' : ''}
            >
              {i}
            </button>
          );
        }
        pages.push(<span key="ellipsis1">...</span>);
        pages.push(
          <button key={totalPages} onClick={() => paginate(totalPages)}>
            {totalPages}
          </button>
        );
      } else if (currentPage > totalPages - Math.floor(pageLimit / 2)) {
        pages.push(
          <button key={1} onClick={() => paginate(1)}>
            1
          </button>
        );
        pages.push(<span key="ellipsis2">...</span>);
        for (let i = totalPages - pageLimit + 1; i <= totalPages; i++) {
          pages.push(
            <button
              key={i}
              onClick={() => paginate(i)}
              className={currentPage === i ? 'active' : ''}
            >
              {i}
            </button>
          );
        }
      } else {
        pages.push(
          <button key={1} onClick={() => paginate(1)}>
            1
          </button>
        );
        pages.push(<span key="ellipsis3">...</span>);
        for (let i = currentPage - Math.floor(pageLimit / 2); i <= currentPage + Math.floor(pageLimit / 2); i++) {
          pages.push(
            <button
              key={i}
              onClick={() => paginate(i)}
              className={currentPage === i ? 'active' : ''}
            >
              {i}
            </button>
          );
        }
        pages.push(<span key="ellipsis4">...</span>);
        pages.push(
          <button key={totalPages} onClick={() => paginate(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    pages.push(
      <button key="next" onClick={handleNextPage} disabled={currentPage === totalPages}>
        &gt;
      </button>
    );

    return pages;
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
        <h1>Check Var</h1>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
          />
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Date Time</th>
                <th>Transaction Comment</th>
                <th>Credit</th>
                <th>Offset Name</th>
              </tr>
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((row, index) => (
                  <tr key={index}>
                    <td>{row['No']}</td>
                    <td>{row['Date Time']}</td>
                    <td>{row['Transaction Comment']}</td>
                    <td>{row['Credit']}</td>
                    <td>{row['Offset Name']}</td>
                  </tr>
                ))
              ) : (
                <tr><td colSpan="5">No data available</td></tr>
              )}
            </tbody>
          </table>
          <div className="pagination">
            {renderPagination()}
          </div>
        </>
      )}
    </div>
  );
};

export default App;
